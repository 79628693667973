@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

div[role="status"] {
  margin: 0px 2px 0px 10px;
}

/* Target divs with primary="#e43d3d" and add padding-top of 4px */
div[primary="#e43d3d"] {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  word-break: break-word; /* Break long words */
  overflow-wrap: break-word; /* Wrap text to the next line if necessary */
  hyphens: auto; /* Optionally, add hyphens to break words at appropriate places */
}

/* Target divs with primary="#16a085" and add padding-top of 4px */
div[primary="#16a085"] {
  padding-top: 20px;
}

.videoIframe > iframe {
  border-radius: 10px;
  height: 260px;
  width: 100%;
  border: none;
}

/* .videoIframe > iframe {
  border-radius: 10px;
  height: 200px;
  width: 100%;
} */

.dropdown-down {
  top: 100%;
}

.dropdown-up {
  bottom: 100%;
}

.custom-scroll::-webkit-scrollbar {
  height: 0.3em;
  width: 0.3em;
}

.custom-scroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: transparent;
  outline: none;
}

.custom-scroll-2::-webkit-scrollbar-thumb {
  background-color: #bfc2c8;
  outline: 1px solid #d1d5db;
  border-radius: 2px;
}

.custom-scroll-2::-webkit-scrollbar {
  height: 0.3em;
  width: 0.6em;
}

.custom-scroll-2::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: transparent;
  outline: none;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #bfc2c8;
  outline: 1px solid #d1d5db;
  border-radius: 2px;
}

.custom-scroll-sidebar::-webkit-scrollbar {
  height: 0.6em;
  width: 0.3em;
}

.custom-scroll-sidebar::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: transparent;
  outline: none;
}

.custom-scroll-sidebar::-webkit-scrollbar-thumb {
  background-color: #9f9595;
  outline: 1px solid #9f9595;
  border-radius: 2px;
}

.ant-picker-input > input::placeholder {
  color: #00000099 !important;
}

.ant-picker-outlined:focus {
  border: 1px solid #00000099 !important;
}

.ant-picker-outlined {
  border-width: 1px;
  border-style: solid;
  border-color: #0000003c !important;
}

.ant-picker-outlined:hover {
  border-width: 1px;
  border-style: solid;
  border-color: #00000022 !important;
}

.hidetext {
  -webkit-text-security: disc;
}

.custom-input > div > div > input {
  box-shadow: none;
  background: #ffffff;
  border: 1px solid rgb(0, 0, 0, 0.2);
}

.custom-input > div > div > input:focus {
  box-shadow: none;
  border: 1px solid rgb(0, 0, 0, 0.2);
}

textarea:focus,
option:focus,
select:focus,
input:focus {
  outline: none !important;
}

select {
  appearance: none;
}

select:not([size]) {
  background-image: url("assests/Icon/arrow-down.svg");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 0.75em 0.75em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.shine-line {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 31.56%,
    rgba(255, 255, 255, 0.6) 59.69%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 22px;
  width: 2.5px;
  transform: rotate(30deg);
}

.rectangle-card {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -9px;
  left: -16px;
  background: linear-gradient(90deg, #2673cd 0%, #2673cd 100%);
  height: 20px;
  width: 200px;

  border-bottom-right-radius: 30px;
}

.square-card {
  position: absolute;
  top: 1px;
  left: -12px;
  background: linear-gradient(90deg, #0a4488 0%, #0a4488 100%);
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
  z-index: -1;
}

.custom-shine {
  position: absolute;
  top: 0;
  left: -100%;
  right: -100%;
  height: 60px;
  width: 50%;
  z-index: 5;
  display: block;
  transform: skewX(-30deg);
  /* background: hsl(0, 89%, 50%); */
  background: #ffffff;
  opacity: 0.3;
}

.modal-content {
  /* Prevent content from being pushed up by keyboard */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
}

.drawer-content {
  height: 100vh;
  height: -webkit-fill-available;
  overflow-y: auto;
  padding-bottom: env(safe-area-inset-bottom);
}
/* .custom-shine {
  position: absolute;
  top: 0;
  left: 0%;
  right: -100%;
  height: 60px;
  width: 3%;
  z-index: 10;
  display: block;
  transform: skewX(-30deg);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 31.56%, rgba(255, 255, 255, 0.6) 59.69%, rgba(255, 255, 255, 0) 100%);
  opacity: 1;
} */

.group:hover .custom-shine {
  animation: shine 0.6s;
}

@keyframes shine {
  100% {
    left: 110%;
  }
}

@media (max-width: 400px) {
  .videoIframe > iframe {
    border-radius: 10px;
    height: 180px;
    width: 100%;
  }
}
/* Style for dropdown container */
.new-box-shadow {
  box-shadow: 3px 6px 30px 0px #00000014;
}
.animate-modal {
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes scaleUp {
  0% {
    margin-top: 200px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}

.animate-modal[data-state="open"] {
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards !important;
}

@keyframes slide-next {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-prev {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slide-next {
  animation: slide-next 0.5s ease-in-out forwards;
}

.animate-slide-prev {
  animation: slide-prev 0.5s ease-in-out forwards;
}

@keyframes pop-up {
  0% {
    opacity: 0.5;
    transform: scale(0.9) translateY(10px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.animate-pop-up {
  animation: pop-up 0.5s ease-out forwards;
}
