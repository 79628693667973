.strategiescontainerBreadcrumb {
  padding-top: 100px !important;
}

.strategiescontainer,
.strategiescontainerBreadcrumb {
  width: 1280px;
  max-width: 1280px;
  margin: auto !important;
  padding-left: 10px;
}

.linkStyle {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 30px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  padding-right: 15px !important;
}

.lastLink > a {
  color: #000000;
  cursor: default;
}

.lastLink > img {
  display: none !important;
}

.lastLink:hover {
  color: #000000;
}

.breadcrumbWrap {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
}

@media screen and (max-width: 700px) and (min-width: 100px) {
  .strategiescontainerBreadcrumb {
    width: 100% !important;
    margin: auto !important;
    padding-left: 20px;
    padding-top: 100px !important;
  }

  .strategiescontainer {
    padding-top: 20px;
  }

  .strategiescontainerBreadcrumb {
    padding-bottom: 0 !important;
    padding-top: 60px;
  }

  .containerPadding {
    padding-right: 20px;
  }
}
